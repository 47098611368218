import React from 'react';

const errorStyle = {
  color: '#FF0000',
  fontSize: '12px',
  marginLeft: '10px',
  marginTop: '5px',
};

const flexColumn = {
  display: 'flex',
  flexDirection: 'column',
};

const Dvd = ({ register, errors }) => {
  return (
    <>
      <h3 style={{ margin: '10px 0', fontWeight: 'bold' }}>Provide Size</h3>
      <div className='form-control'>
        <label htmlFor='' className='label'>
          Size(MB)
        </label>
        <div style={flexColumn}>
          <input
            type='number'
            {...register('size', { required: true })}
            id='size'
          />
          {errors && <p style={errorStyle}>{errors.size?.message}</p>}
        </div>
        <p style={{ marginLeft: '10px', fontSize: '12px' }}>
          Please enter a DVD disc size(MB)
        </p>
      </div>
    </>
  );
};

const Forniture = ({ register, errors }) => {
  return (
    <div className='forniture'>
      <h3 style={{ margin: '10px 0', fontWeight: 'bold' }}>
        Provide dimensions
      </h3>
      <div className='form-control'>
        <label htmlFor='' className='label'>
          Height(CM)
        </label>
        <div style={flexColumn}>
          <input
            type='text'
            id='height'
            {...register('size.height', {
              required: true,
            })}
          />
          {errors && (
            <p style={{ ...errorStyle, marginLeft: '30px' }}>
              {errors.size?.height?.message}
            </p>
          )}
        </div>
        <p style={{ marginLeft: '10px', fontSize: '12px' }}>
          Please enter a Height.
        </p>
      </div>
      <div className='form-control'>
        <label htmlFor='' className='label'>
          Width(CM)
        </label>
        <div style={flexColumn}>
          <input
            type='text'
            id='width'
            {...register('size.width', { required: true })}
          />
          {errors && (
            <p style={{ ...errorStyle, marginLeft: '30px' }}>
              {errors.size?.width?.message}
            </p>
          )}
        </div>
        <p style={{ marginLeft: '10px', fontSize: '12px' }}>
          Please enter a Width.
        </p>
      </div>
      <div className='form-control'>
        <label htmlFor='' className='label'>
          Length(CM)
        </label>
        <div style={flexColumn}>
          <input
            type='text'
            id='length'
            {...register('size.length', {
              required: { required: true },
            })}
          />
          {errors && (
            <p style={{ ...errorStyle, marginLeft: '30px' }}>
              {errors.size?.length?.message}
            </p>
          )}
        </div>
        <p style={{ marginLeft: '10px', fontSize: '12px' }}>
          Please enter a Length.
        </p>
      </div>
    </div>
  );
};

const Book = ({ errors, register }) => {
  return (
    <>
      <h3 style={{ margin: '10px 0', fontWeight: 'bold' }}>
        Please, provide weight
      </h3>
      <div className='form-control'>
        <label htmlFor='' className='label'>
          Weight (KG)
        </label>
        <div style={flexColumn}>
          <input
            type='number'
            id='weight'
            style={{ width: '100%', marginRight: '10px' }}
            {...register('size', { required: true })}
          />
          {errors && <p style={errorStyle}>{errors.size?.message}</p>}
        </div>
        <p style={{ marginLeft: '10px', fontSize: '12px' }}>
          Please enter a Book weight
        </p>
      </div>
    </>
  );
};

const DynamicForm = ({ selects, errors, register }) => {
  return (
    <div>
      {selects === 'dvd' ? (
        <Dvd errors={errors} register={register} />
      ) : selects === 'furniture' ? (
        <Forniture errors={errors} register={register} />
      ) : selects === 'book' ? (
        <Book errors={errors} register={register} />
      ) : null}
    </div>
  );
};

export default DynamicForm;
